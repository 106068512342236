import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "./invoices.css";

const Invoices = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [ledgerData, setLedgerData] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [showInvoiceNumberField, setShowInvoiceNumberField] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [invoiceMonth, setInvoiceMonth] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredLedgerData, setFilteredLedgerData] = useState([]);

  useEffect(() => {
    fetch("https://almamzarcarwash.com:3001/api/companiesinfo")
      .then((response) => response.json())
      .then((data) => setCompanies(data))
      .catch((error) => console.error("Error fetching companies:", error));
  }, []);

  const handleShowAvailedServices = async () => {
    if (!selectedCompany) {
      alert("Please select a company first!");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `https://almamzarcarwash.com:3001/api/ledgerEntries?entityType=company&entityName=${encodeURIComponent(
          selectedCompany
        )}`
      );
      const data = await response.json();

      if (response.ok) {
        setLedgerData(data);
        setFilteredLedgerData(data);
        setShowInvoiceNumberField(true);
        console.log("Fetched Data:", data); // Log fetched data to check date format
        const company = companies.find(
          (company) => company.name === selectedCompany
        );
        setCompanyInfo(company || {});
      } else {
        console.error("Failed to fetch ledger entries:", data.error);
      }
    } catch (error) {
      console.error("Error fetching ledger entries:", error);
    }

    setLoading(false);
  };
  const handleFilterByDate = () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }

    // Helper function to normalize date from "DD-MMM-YYYY" to "YYYY-MM-DD"
    const convertDateToISO = (dateStr) => {
      const months = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
      };
      const [day, month, year] = dateStr.split("-");
      return `${year}-${months[month]}-${day.padStart(2, "0")}`;
    };
  
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    // Filter ledgerData based on date range
    const filteredData = ledgerData.filter((entry) => {
      const rawEntryDate = entry.Date;
      const isoEntryDate = convertDateToISO(rawEntryDate);
  
      const entryDate = new Date(isoEntryDate);
  
      // Ensure date is within range (inclusive of start and end dates)
      return entryDate >= start && entryDate <= end;
    });
  
    if (filteredData.length === 0) {
      console.log("No records found for the selected date range.");
      alert("No records found for the selected date range.");
    } else {
      console.log(`Filtered records count: ${filteredData.length}`);
    }
  
    setFilteredLedgerData(filteredData);
  };
          
  const generateOnePageInvoice = (type) => {
    if (filteredLedgerData.length === 0) {
      alert("No filtered data available to generate an invoice.");
      return;
    }
  
    const doc = new jsPDF();
  
    // Header Section
    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.text("AL MAMZAR CARS WASHING LLC", 105, 20, { align: "center" });
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Vehicle Service Station", 105, 28, { align: "center" });
  
    doc.setFont("helvetica", "bold");
    // Invoice Details
    doc.line(10, 35, 200, 35);
    doc.setFontSize(10);
    doc.text(`Date: ${new Date().toLocaleDateString("en-GB")}`, 10, 40);
    doc.text(`Invoice Month: ${invoiceMonth || "N/A"}`, 100, 40, { align: "center" }); // Invoice Month in the center
    
    const invoiceType =
      type === "withVAT"
        ? "Tax Invoice"
        : type === "withoutVAT"
        ? "Invoice"
        : "Invoice";
    doc.text(`${invoiceType} No: ${invoiceNumber || "N/A"}`, 200, 40, { align: "right" });
  
    // Our Company Details Box
    doc.text("AlMamzar Car Wash Details:", 10, 50);
    doc.setFont("helvetica", "normal");
    doc.text("Warehouse No. 2, P.O Box: 63798", 10, 55);
    doc.text("Jurf Industrial Zone 2, Ajman, UAE", 10, 60);
    doc.text("TRN: 104630932200003", 10, 65);
    doc.text("IBAN: AE710400008193410093901", 10, 70);
    doc.text("Tel: +971 50 367 2862", 10, 75);
    doc.text("Email: almamzarcarwash@gmail.com", 10, 80);
    doc.rect(8, 45, 95, 40);
  
    // Client Details Box
    doc.setFont("helvetica", "bold");
    doc.text("Client Details:", 110, 50);
    doc.setFont("helvetica", "normal");
    const { name, address, contactPerson, trnNo, contactNo } = companyInfo;
    doc.text(`Name: ${name || "N/A"}`, 110, 55);
    doc.text(`Contact Person: ${contactPerson || "N/A"}`, 110, 60);
  
    const wrappedAddress = doc.splitTextToSize(`Address: ${address || "N/A"}`, 85);
    wrappedAddress.forEach((line, index) => doc.text(line, 110, 65 + index * 5));
  
    const contactPositionY = 65 + wrappedAddress.length * 5;
    doc.text(`Contact No: ${contactNo || "N/A"}`, 110, contactPositionY);
  
    const trnPositionY = contactPositionY + 5;
    doc.text(`TRN: ${trnNo || "N/A"}`, 110, trnPositionY);
  
    const rectHeight = trnPositionY - 45 + 5;
    doc.rect(108, 45, 92, rectHeight);

// Ledger Summary Table with refined design
const tableStartY = 100;
const rowHeight = 10;
const colDescriptionX = 15;
const colAmountX = 180; // Adjusted to bring the Total Amount to the right
const tableWidth = 190;

// Draw Table Header
doc.setFont("helvetica", "bold");
doc.setFontSize(11);
doc.rect(10, tableStartY, tableWidth, rowHeight); // Header box
doc.text("Description", colDescriptionX, tableStartY + 7); // Adjusted vertical alignment
doc.text("Total Amount (AED)", colAmountX, tableStartY + 7, { align: "right" });

// Draw Table Rows
doc.setFont("helvetica", "normal");
const description = "Vehicle washing, greasing and polishing";

let subtotal = filteredLedgerData.reduce((sum, entry) => {
  const debit = parseFloat(entry.Debit) || 0;
  return sum + debit;
}, 0);

let adjustedTotal;
if (type === "normal") {
  adjustedTotal = subtotal;
} else if (type === "withoutVAT") {
  adjustedTotal = subtotal / 1.05;
} else if (type === "withVAT") {
  adjustedTotal = subtotal / 1.05;
}

const tableContentY = tableStartY + rowHeight;
doc.rect(10, tableContentY, tableWidth, rowHeight); // Content row box
doc.text(description, colDescriptionX, tableContentY + 7); // Adjusted vertical alignment
doc.text(adjustedTotal.toFixed(2), colAmountX, tableContentY + 7, { align: "right" });

// Total, Subtotal, VAT Boxes (Enclosed in a single boundary)
let currentY = tableContentY + rowHeight + 5;
doc.setFont("helvetica", "bold");

if (type === "withVAT") {
  const vatAmount = adjustedTotal * 0.05;
  const grandTotal = adjustedTotal + vatAmount;

  // Draw outer boundary for Subtotal, VAT, and Total
  const boxWidth = 50;
  const boxStartX = colAmountX - 40; // Start position for the boundary
  const boxStartY = currentY;
  const totalRowsHeight = rowHeight * 3; // 3 rows
  doc.rect(boxStartX, boxStartY, boxWidth, totalRowsHeight); // Outer boundary

  // Subtotal row
  doc.line(boxStartX, currentY + rowHeight, boxStartX + boxWidth, currentY + rowHeight); // Row separator
  doc.text("Subtotal:", boxStartX + 5, currentY + rowHeight - 3); // Inside the box
  doc.text(`${adjustedTotal.toFixed(2)}`, boxStartX + boxWidth - 5, currentY + rowHeight - 3, {
    align: "right",
  });

  currentY += rowHeight;

  // VAT row
  doc.line(boxStartX, currentY + rowHeight, boxStartX + boxWidth, currentY + rowHeight); // Row separator
  doc.text("VAT (5%):", boxStartX + 5, currentY + rowHeight - 3); // Inside the box
  doc.text(`${vatAmount.toFixed(2)}`, boxStartX + boxWidth - 5, currentY + rowHeight - 3, {
    align: "right",
  });

  currentY += rowHeight;

  // Total row
  doc.text("Total:", boxStartX + 5, currentY + rowHeight - 3); // Inside the box
  doc.text(`${grandTotal.toFixed(2)}`, boxStartX + boxWidth - 5, currentY + rowHeight - 3, {
    align: "right",
  });
} else {
  // Single row for Total only
  const boxWidth = 50;
  const boxStartX = colAmountX - 40; // Start position for the box
  const boxStartY = currentY;

  doc.rect(boxStartX, boxStartY, boxWidth, rowHeight); // Outer boundary
  doc.text("Total:", boxStartX + 5, currentY + rowHeight - 3); // Inside the box
  doc.text(`${adjustedTotal.toFixed(2)}`, boxStartX + boxWidth - 5, currentY + rowHeight - 3, {
    align: "right",
  });
}


  
// Footer Section
const footerStartY = currentY + rowHeight + 10;

// Receiver Name and Prepared By
doc.setFont("helvetica", "normal");
doc.text("Receiver Name:", 140, footerStartY+30);
doc.text("Prepared By:", 10, footerStartY+30);

// Receiver Sign & Stamp and Checked By
doc.text("Rec Sign & Stamp:", 140, footerStartY + 40);
doc.text("Checked By:", 10, footerStartY +40);

// Footer Note
doc.setFont("helvetica", "bold");
doc.text("Thanks, and regards", 10, footerStartY + 70);
doc.setFont("helvetica", "normal");
doc.text("On Behalf of Al Mamzar Car Wash. Ajman.", 10, footerStartY + 80);

doc.setFont("helvetica", "bold");
doc.text("Mw Wasi", 10, footerStartY + 90);
doc.setFont("helvetica", "normal");
doc.text("06-530-4130", 10, footerStartY + 100);
doc.text("050-367-2862", 10, footerStartY + 110);

  
    // Save file based on type
    const fileName =
      type === "normal"
        ? "Normal_Invoice.pdf"
        : type === "withVAT"
        ? "Invoice_With_VAT.pdf"
        : "Invoice_Without_VAT.pdf";
  
    doc.save(fileName);
  };

  
  const generateInvoice = (type) => {
    if (filteredLedgerData.length === 0) {
      alert("No filtered data available to generate an invoice.");
      return;
    }

    const doc = new jsPDF();

    // Header Section
    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.text("AL MAMZAR CARS WASHING LLC", 105, 20, { align: "center" });
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Vehicle Service Station", 105, 28, { align: "center" });

    // Invoice Details
    doc.line(10, 35, 200, 35);
    doc.setFontSize(10);
    doc.text(`Date: ${new Date().toLocaleDateString("en-GB")}`, 10, 40);
    doc.setFont("helvetica", "bold");
  doc.text(`Supporting Docs`, 150, 40);

    // Our Company Details Box
    doc.setFont("helvetica", "bold");
    doc.text("AlMamzar Car Wash Details:", 10, 50);
    doc.setFont("helvetica", "normal");
    doc.text("Warehouse No. 2, P.O Box: 63798", 10, 55);
    doc.text("Jurf Industrial Zone 2, Ajman, UAE", 10, 60);
    doc.text("TRN: 104630932200003", 10, 65);
    doc.text("IBAN: AE710400008193410093901", 10, 70);
    doc.text("Tel: +971 50 367 2862", 10, 75);
    doc.text("Email: almamzarcarwash@gmail.com", 10, 80);
    doc.rect(8, 45, 95, 40);

    // Client Details Box
    doc.setFont("helvetica", "bold");
    doc.text("Client Details:", 110, 50);
    doc.setFont("helvetica", "normal");
    const { name, address, contactPerson, trnNo, contactNo } = companyInfo;
    doc.text(`Name: ${name || "N/A"}`, 110, 55);
    doc.text(`Contact Person: ${contactPerson || "N/A"}`, 110, 60);

    const wrappedAddress = doc.splitTextToSize(`Address: ${address || "N/A"}`, 85);
    wrappedAddress.forEach((line, index) => doc.text(line, 110, 65 + index * 5));

    const contactPositionY = 65 + wrappedAddress.length * 5;
    doc.text(`Contact No: ${contactNo || "N/A"}`, 110, contactPositionY);

    const trnPositionY = contactPositionY + 5;
    doc.text(`TRN: ${trnNo || "N/A"}`, 110, trnPositionY);

    const rectHeight = trnPositionY - 45 + 5;
    doc.rect(108, 45, 92, rectHeight);

    // Ledger Table Header
    doc.setFont("helvetica", "bold");
    doc.text("Date", 10, 100);
    doc.text("Description", 40, 100);
    doc.text("Debit (AED)", 120, 100, { align: "right" });
    doc.text("Credit (AED)", 150, 100, { align: "right" });
    doc.text("Balance (AED)", 190, 100, { align: "right" });
    doc.line(10, 102, 200, 102);

    // Ledger Table Rows with description wrapping
    doc.setFont("helvetica", "normal");
    let startY = 110;
    let subtotal = 0;
    filteredLedgerData.forEach((entry) => {
      const debit = parseFloat(entry.Debit) || 0;
      const credit = parseFloat(entry.Credit) || 0;

      const adjustedDebit =
      type === "normal" ? debit : debit / 1.05;
    
    subtotal += type === "normal" ? debit : debit / 1.05;
    
      const description =
        credit > 0
          ? `Payment received from ${companyInfo.name || "the company"}`
          : entry.Description || "-";

      const wrappedDescription = doc.splitTextToSize(description, 65);

      doc.rect(10, startY - 5, 190, 10 + (wrappedDescription.length - 1) * 5);

      wrappedDescription.forEach((line, i) => {
        doc.text(line, 35, startY + i * 5);
      });

      doc.text(entry.Date || "-", 10, startY);
      doc.text(adjustedDebit.toFixed(2), 120, startY, { align: "right" });
      doc.text(credit > 0 ? credit.toFixed(2) : "-", 150, startY, { align: "right" });
      doc.text(adjustedDebit.toFixed(2), 190, startY, { align: "right" });
      startY += 10 + (wrappedDescription.length - 1) * 5;

      if (startY > 280) {
        doc.addPage();
        startY = 10;
        doc.setFont("helvetica", "bold");
        doc.text("Date", 10, startY);
        doc.text("Description", 40, startY);
        doc.text("Debit (AED)", 120, startY, { align: "right" });
        doc.text("Credit (AED)", 150, startY, { align: "right" });
        doc.text("Balance (AED)", 190, startY, { align: "right" });
        doc.line(10, startY + 2, 200, startY + 2);
        startY += 10;
        doc.setFont("helvetica", "normal");
      }
    });

    startY += 10;
    doc.setFont("helvetica", "bold");
    doc.text(`Subtotal: AED ${subtotal.toFixed(2)}`, 150, startY);

    if (type==="withVAT") {
      const vatAmount = subtotal * 0.05;
      const grandTotal = subtotal + vatAmount;

      startY += 10;
      doc.text(`VAT (5%): AED ${vatAmount.toFixed(2)}`, 150, startY);

      startY += 10;
      doc.text(`Total: AED ${grandTotal.toFixed(2)}`, 150, startY);
    } else {
      startY += 10;
      doc.text(`Total: AED ${subtotal.toFixed(2)}`, 150, startY);
    }

    startY += 20;
    doc.setFont("helvetica", "normal");
    doc.text("Prepared By: __________________", 10, startY);
    doc.text("Signed By: __________________", 140, startY);
    startY += 10;
    doc.text("Thank you for working with us!", 105, startY, { align: "center" });

    // Save file based on type
  const fileName =
  type === "normal"
    ? "Normal_Invoice.pdf"
    : type === "withVAT"
    ? "Invoice_With_VAT.pdf"
    : "Invoice_Without_VAT.pdf";

doc.save(fileName);
  };

  return (
    <div
      className="invoices-container"
      style={{ maxHeight: "90vh", overflowY: "scroll" }}
    >
      <h1>Generate Invoice</h1>
      <div className="form-group">
        <label htmlFor="company">Company:</label>
        <select
          id="company"
          value={selectedCompany}
          onChange={(e) => setSelectedCompany(e.target.value)}
        >
          <option value="">Select a Company</option>
          {companies.map((company) => (
            <option key={company.id} value={company.name}>
              {company.name}
            </option>
          ))}
        </select>
        <button
          onClick={handleShowAvailedServices}
          className="show-services-button"
        >
          Show Availed Services
        </button>
      </div>
  
      {showInvoiceNumberField && (
  <div className="form-group">
    <label htmlFor="invoiceNumber">Invoice Number:</label>
    <input
      type="text"
      id="invoiceNumber"
      value={invoiceNumber}
      onChange={(e) => setInvoiceNumber(e.target.value)}
      placeholder="Enter Invoice Number"
    />
  </div>
)}

{showInvoiceNumberField && (
  <div className="form-group">
    <label htmlFor="invoiceMonth">Invoice Month:</label>
    <input
      type="text"
      id="invoiceMonth"
      value={invoiceMonth}
      onChange={(e) => setInvoiceMonth(e.target.value)}
      placeholder="Enter Invoice Month"
    />
  </div>
)}
  
      {ledgerData.length > 0 && (
        <div
          className="date-filter-container"
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <label>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
          <button onClick={handleFilterByDate} className="filter-button">
            Filter
          </button>
        </div>
      )}
  
      {loading ? (
        <p>Loading...</p>
      ) : (
        filteredLedgerData.length > 0 && (
          <>
            <div className="entries-table-container">
              <table className="entries-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredLedgerData.map((entry, index) => {
                    const description =
                      entry.Credit > 0
                        ? `Payment received from ${companyInfo.name || "the company"}`
                        : entry.Description || "-";
  
                    return (
                      <tr key={index}>
                        <td>{entry.Date}</td>
                        <td>{description}</td>
                        <td>{entry.Debit ? entry.Debit : "-"}</td>
                        <td>{entry.Credit ? entry.Credit : "-"}</td>
                        <td>{entry.Balance}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="button-group">
  <button onClick={() => generateOnePageInvoice("normal")} className="generate-pdf-button">
    Generate Invoice (Normal)
  </button>
  <button onClick={() => generateOnePageInvoice("withoutVAT")} className="generate-pdf-button">
    Generate Invoice (Without VAT)
  </button>
  <button onClick={() => generateOnePageInvoice("withVAT")} className="generate-pdf-button">
    Generate Invoice (With VAT)
  </button>
</div>

            <div className="button-group">
              <button
                onClick={() => generateInvoice("normal")}
                className="generate-pdf-button"
              >
                Supporting Docs (Normal)
              </button>
              <button
                onClick={() => generateInvoice("withoutVAT")}
                className="generate-pdf-button"
              >
                Supporting Docs (Without VAT)
              </button>
              <button
                onClick={() => generateInvoice("withVAT")}
                className="generate-pdf-button"
              >
                Supporting Docs (With VAT)
              </button>
            </div>
          </>
        )
      )}
    </div>
  );
  
};

export default Invoices;
