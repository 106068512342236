// Ensure the existing frontend code remains unchanged, except for functionality updates.
import React, { useState, useEffect } from "react";
import "./Payments.css";

const Payments = () => {
  const [companies, setCompanies] = useState([]); // List of companies
  const [suppliers, setSuppliers] = useState([]); // List of suppliers
  const [selectedEntity, setSelectedEntity] = useState(""); // Selected company or supplier
  const [currentBalance, setCurrentBalance] = useState(0); // Current balance
  const [paymentAmount, setPaymentAmount] = useState(""); // Payment amount entered
  const [paymentDate, setPaymentDate] = useState(""); // Payment date
  const [paymentMethod, setPaymentMethod] = useState(""); // Payment method
  const [paymentNotes, setPaymentNotes] = useState(""); // Notes
  const [entityType, setEntityType] = useState("company"); // Entity type: "company" or "supplier"
  const [message, setMessage] = useState(""); // Success/Error message

  // Fetch companies and suppliers
  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const companiesResponse = await fetch(
          "https://almamzarcarwash.com:3001/api/companies"
        );
        const suppliersResponse = await fetch(
          "https://almamzarcarwash.com:3001/api/suppliers"
        );
        const companiesData = await companiesResponse.json();
        const suppliersData = await suppliersResponse.json();
        setCompanies(companiesData);
        setSuppliers(suppliersData);
      } catch (error) {
        console.error("Error fetching entities:", error);
      }
    };

    fetchEntities();
  }, []);

  const handleEntityChange = async (entityId) => {
    setSelectedEntity(entityId);
    setMessage("");

    if (entityId) {
        try {
            const endpoint =
                entityType === "company"
                    ? `/api/company/balance/${entityId}`
                    : `/api/supplier/balance/${entityId}`; // Use SupplierID directly

            const response = await fetch(`https://almamzarcarwash.com:3001${endpoint}`);
            const data = await response.json();

            if (response.ok) {
                setCurrentBalance(data.balance || 0);
            } else {
                console.error("Failed to fetch balance:", data.error);
                setCurrentBalance(0); // Default balance if API fails
            }
        } catch (error) {
            console.error("Error fetching balance:", error);
            setCurrentBalance(0); // Default balance in case of an error
        }
    } else {
        setCurrentBalance(0); // Reset balance if no entity selected
    }
};

  // Handle payment submission
  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    if (!selectedEntity) {
      alert(`Please select a ${entityType}!`);
      return;
    }

    if (!paymentDate) {
      alert("Please select a payment date!");
      return;
    }

    if (!paymentMethod) {
      alert("Please select a payment method!");
      return;
    }

    if (!paymentAmount || isNaN(paymentAmount) || parseFloat(paymentAmount) <= 0) {
      alert("Please enter a valid payment amount!");
      return;
    }

    try {
      const response = await fetch(
        `https://almamzarcarwash.com:3001/api/${entityType}/payments/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            entityId: selectedEntity,
            amount: parseFloat(paymentAmount),
            date: paymentDate,
            method: paymentMethod,
            notes: paymentNotes,
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setMessage("Payment successfully recorded!");
        alert("Payment submitted successfully!");
        setPaymentAmount("");
        setPaymentDate("");
        setPaymentMethod("");
        setPaymentNotes("");

        // Refresh the balance immediately after successful payment
        await handleEntityChange(selectedEntity);
      } else {
        console.error("Failed to add payment:", data.error);
        setMessage("Error recording payment. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting payment:", error);
      setMessage("Error recording payment. Please try again.");
    }
  };

  return (
    <div className="payments-container">
      <h1>Record Payment</h1>
      <div className="radio-group">
        <label
          className={`radio-label ${
            entityType === "company" ? "selected" : ""
          }`}
        >
          <input
            type="radio"
            name="entityType"
            value="company"
            checked={entityType === "company"}
            onChange={() => {
              setEntityType("company");
              setSelectedEntity("");
              setCurrentBalance(0);
              setMessage("");
            }}
          />
          Company
        </label>
        <label
          className={`radio-label ${
            entityType === "supplier" ? "selected" : ""
          }`}
        >
          <input
            type="radio"
            name="entityType"
            value="supplier"
            checked={entityType === "supplier"}
            onChange={() => {
              setEntityType("supplier");
              setSelectedEntity("");
              setCurrentBalance(0);
              setMessage("");
            }}
          />
          Supplier
        </label>
      </div>

      <div className="form-group">
        <label htmlFor="entity">
          Select {entityType === "company" ? "Company" : "Supplier"}:
        </label>
        <select
    id="entity"
    value={selectedEntity}
    onChange={(e) => handleEntityChange(e.target.value)}
>
    <option value="">
        -- Select {entityType === "company" ? "Company" : "Supplier"} --
    </option>
    {(entityType === "company" ? companies : suppliers).map((entity) => (
        <option key={entity.id} value={entity.id}> {/* Use SupplierID as value */}
            {entity.name}
        </option>
    ))}
</select>

      </div>

      {selectedEntity && (
        <div className="balance-box">
          <h3>Current Balance</h3>
          <p>AED {currentBalance.toFixed(2)}</p>
        </div>
      )}

      {selectedEntity && (
        <form onSubmit={handlePaymentSubmit} className="payment-form">
          <div className="form-row">
            <div className="form-group-inline">
              <label htmlFor="date">Date:</label>
              <input
                type="date"
                id="date"
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
                required
              />
            </div>

            <div className="form-group-inline">
              <label htmlFor="payment-method">Payment Method:</label>
              <select
                id="payment-method"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                required
              >
                <option value="">-- Select Payment Method --</option>
                <option value="cash">Cash</option>
                <option value="bank-transfer">Bank Transfer</option>
                <option value="cheque">Cheque</option>
              </select>
            </div>

            <div className="form-group-inline">
              <label htmlFor="notes">Notes:</label>
              <textarea
                id="notes"
                value={paymentNotes}
                onChange={(e) => setPaymentNotes(e.target.value)}
                placeholder="Enter notes (optional)"
              ></textarea>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-inline full-width">
              <label htmlFor="amount">Payment Amount:</label>
              <input
                type="text"
                id="amount"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e.target.value)}
                placeholder="Enter payment amount"
                required
              />
            </div>
          </div>

          <div className="form-row centered">
            <button type="submit" className="submit-button">
              Record Payment
            </button>
          </div>
        </form>
      )}

      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default Payments;
