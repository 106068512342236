import React, { useState, useEffect } from "react";
import "./ServiceTransaction.css";
import "./Expenses.css";

const Expenses = () => {
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("credit");
  const [expenseType, setExpenseType] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [trnNumber, setTrnNumber] = useState("");
  const [address, setAddress] = useState("");
  const [isOtherExpenseType, setIsOtherExpenseType] = useState(false);
  const [isOtherSupplier, setIsOtherSupplier] = useState(false);

  const [expenseTypes, setExpenseTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const expenseTypesRes = await fetch(
          "https://almamzarcarwash.com:3001/api/expensetypes"
        );
        const suppliersRes = await fetch(
          "https://almamzarcarwash.com:3001/api/expenses/suppliers"
        );

        const expenseTypesData = await expenseTypesRes.json();
        const suppliersData = await suppliersRes.json();

        setExpenseTypes(expenseTypesData);
        setSuppliers(suppliersData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleExpenseTypeChange = (event) => {
    const selectedType = event.target.value;
    setExpenseType(selectedType);
    setIsOtherExpenseType(selectedType === "Other");
  };

  const handleSupplierNameChange = (event) => {
    const selectedSupplier = event.target.value;
    setSupplierName(selectedSupplier);

    if (selectedSupplier === "Other") {
      setIsOtherSupplier(true);
      setContactNumber("");
      setTrnNumber("");
      setAddress("");
    } else {
      setIsOtherSupplier(false);
      const supplierDetails = suppliers.find(
        (supplier) => supplier.name === selectedSupplier
      );
      if (supplierDetails) {
        setContactNumber(supplierDetails.ContactNumber || "");
        setTrnNumber(supplierDetails.TRN || "");
        setAddress(supplierDetails.Address || "");
      }
    }
  };

  const handleAddExpense = async () => {
    const expenseData = {
      Date: date,
      Description: description || `Purchase of "${expenseType}" from ${supplierName} via ${paymentMethod}.`,
      ExpenseAmount: parseFloat(expenseAmount) || 0,
      PaymentMethod: paymentMethod,
      ExpenseType: isOtherExpenseType ? "Other" : expenseType,
      NewExpenseType: isOtherExpenseType ? expenseType : null,
      SupplierName: supplierName,
      ContactNumber: isOtherSupplier ? contactNumber : undefined,
      TRN: isOtherSupplier ? trnNumber : undefined,
      Address: isOtherSupplier ? address : undefined,
    };

    try {
      if (isOtherExpenseType) {
        await fetch("https://almamzarcarwash.com:3001/api/expensetypes/add", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ TypeName: expenseType }),
        });
      }

      if (isOtherSupplier) {
        await fetch("https://almamzarcarwash.com:3001/api/expenses/suppliers/add", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            SupplierName: supplierName,
            ContactNumber: contactNumber,
            TRN: trnNumber,
            Address: address,
          }),
        });
      }

      const response = await fetch(
        "https://almamzarcarwash.com:3001/api/expenses/add",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(expenseData),
        }
      );

      const result = await response.json();

      if (result.success) {
        alert("Expense added successfully!");
        setDate("");
        setDescription("");
        setExpenseAmount("");
        setPaymentMethod("credit");
        setExpenseType("");
        setSupplierName("");
        setContactNumber("");
        setTrnNumber("");
        setAddress("");
      } else {
        throw new Error(result.error || "Failed to add expense.");
      }
    } catch (error) {
      console.error("Error adding expense:", error);
      alert("Failed to add expense. Please check the input and try again.");
    }
  };

  return (
    <div>
      <div className="service-transaction-container">
        <div className="form-grid">
        <div className="form-group">
  <label>Supplier Name:</label>
  {!isOtherSupplier ? (
    <select value={supplierName} onChange={handleSupplierNameChange}>
      <option value="">Select supplier</option>
      {suppliers.map((supplier) => (
        <option key={supplier.id} value={supplier.SupplierName}>
          {supplier.SupplierName}
        </option>
      ))}
      <option value="Other">Other</option>
    </select>
  ) : (
    <input
      type="text"
      placeholder="Enter supplier name"
      value={supplierName}
      onChange={(e) => setSupplierName(e.target.value)}
    />
  )}
</div>
 {isOtherSupplier && (
            <>
              <div className="form-group">
                <label>Contact Number:</label>
                <input
                  type="text"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>TRN:</label>
                <input
                  type="text"
                  value={trnNumber}
                  onChange={(e) => setTrnNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Address:</label>
                <textarea
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </>
          )}
          <div className="form-group">
            <label>Date:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Description:</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Expense Amount:</label>
            <input
              type="text"
              value={expenseAmount}
              onChange={(e) => setExpenseAmount(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Payment Method:</label>
            <select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
              <option value="cash">Cash</option>
            </select>
          </div>
          <div className="form-group">
            <label>Expense Type:</label>
            {!isOtherExpenseType ? (
              <select value={expenseType} onChange={handleExpenseTypeChange}>
                <option value="">Select expense type</option>
                {expenseTypes.map((type) => (
                  <option key={type.ExpenseTypeID} value={type.TypeName}>
                    {type.TypeName}
                  </option>
                ))}
                <option value="Other">Other</option>
              </select>
            ) : (
              <input
                type="text"
                placeholder="Enter expense type"
                value={expenseType}
                onChange={(e) => setExpenseType(e.target.value)}
              />
            )}
          </div>
        </div>
        <div className="button-container">
          <button onClick={handleAddExpense} className="add-expense-btn">
            Add Expense
          </button>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
