import React, { useEffect, useState } from "react";
import "./Ledger.css";

const Ledger = () => {
  const [ledgerData, setLedgerData] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);
  const [entityType, setEntityType] = useState("supplier");

  useEffect(() => {
    const fetchEntities = async () => {
      if (!entityType) return;

      try {
        const response = await fetch(
          `https://almamzarcarwash.com:3001/api/${
            entityType === "supplier" ? "suppliers" : "companies"
          }`
        );
        const data = await response.json();

        if (Array.isArray(data)) {
          setEntityOptions(data); // Set options if data is an array
        } else {
          setEntityOptions([]);
          console.error("Unexpected response format:", data);
        }
      } catch (error) {
        console.error(`Error fetching ${entityType} names:`, error);
      }
    };

    fetchEntities();
  }, [entityType]);

  const fetchLedgerEntries = async () => {
    if (!selectedEntity) {
      alert("Please select an entity");
      return;
    }

    try {
      const response = await fetch(
        `https://almamzarcarwash.com:3001/api/ledgerEntries?entityType=${entityType}&entityName=${selectedEntity}`
      );
      const data = await response.json();
      setLedgerData(data);
    } catch (error) {
      console.error("Error fetching ledger entries:", error);
    }
  };

  return (
    <div className="ledger-container">
      <h1>Ledger</h1>

      <div className="ledger-selector">
        <div className="dropdown-container">
          <label htmlFor="entityTypeSelect">Select Entity Type:</label>
          <select
            id="entityTypeSelect"
            value={entityType}
            onChange={(e) => setEntityType(e.target.value)}
          >
            <option value="supplier">Supplier</option>
            <option value="company">Company</option>
            <option value="customer">Customer</option>
          </select>
        </div>

        <div className="dropdown-container">
          <label htmlFor="entitySelect">Select Entity:</label>
          <select
            id="entitySelect"
            value={selectedEntity}
            onChange={(e) => setSelectedEntity(e.target.value)}
          >
            <option value="">--Select Entity--</option>
            {entityOptions.map((entity, index) => (
              <option key={index} value={entity.name || entity}>
                {entity.name || entity}
              </option>
            ))}
          </select>
        </div>

        <button onClick={fetchLedgerEntries}>Generate Ledger</button>
      </div>

      <div className="entries-table-container">


        <table className="entries-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
  {ledgerData.length === 0 ? (
    <tr>
      <td colSpan="5" style={{ textAlign: "center" }}>
        No entries found.
      </td>
    </tr>
  ) : (
    ledgerData.map((entry, index) => {
      const description =
        entry.Credit > 0
          ? `Payment received from ${selectedEntity || "the entity"}`
          : entry.Description || "-";

      return (
        <tr key={index}>
          <td>{new Date(entry.Date).toLocaleDateString()}</td>
          <td>{description}</td>
          <td>{entry.Debit ? entry.Debit : "-"}</td>
          <td>{entry.Credit ? entry.Credit : "-"}</td>
          <td>{entry.Balance}</td>
        </tr>
      );
    })
  )}
</tbody>

        </table>
      </div>
    </div>
  );
};
export default Ledger;

